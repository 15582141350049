import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Map from 'pigeon-maps';
import Marker from 'pigeon-marker';
import Overlay from 'pigeon-overlay';

import SiteComponent from '../../components/SiteComponent';
import Strip from '../../components/Strip';
import Translate from '../../components/Translate';
import styles from './style.module.css';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Layout from '../../components/Layout';

/**
 * Template class for Page Wordpress Post Type
 */
class Contacts extends SiteComponent {
	constructor(props) {
		super(props, true);
		this.state = {
			email: 'franco.berton@gamgroup.it',
			form: {
				name: '',
				email: '',
				phone: '',
				message: '',
				web: false,
				sap: false,
				as400: false,
			},
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	initState() {
		this.setState({
			form: {
				name: '',
				email: '',
				phone: '',
				message: '',
				web: false,
				sap: false,
				as400: false,
			},
		});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.initState();

		this.setState({
			form: {
				[name]: value,
			},
		});
	}

	render() {
		const contactPage = this.props.data.page;
		const fields = this.props.data.page.acf;

		/**
		 * Services strip
		 **/

		let headerStripTag = (
			<Strip className={styles.contactsStrip}>
				<h1 className={styles.contactsTitle} dangerouslySetInnerHTML={{ __html: contactPage.title }} />
				<p className={styles.contactsDescription} dangerouslySetInnerHTML={{ __html: fields.subtitle }} />
				<p
					className={styles.contactsInfoEmail}
					dangerouslySetInnerHTML={{ __html: fields.info_email + ' - ' + fields.telephone }}
				/>
			</Strip>
		);

		let formStripTag = (
			<Strip className={styles.formStrip}>
				<h1 className={styles.formTitle}>
					<Translate>contactPage.formTitle</Translate>
				</h1>
				<p className={styles.formDescription}>
					<Translate>contactPage.formDescription</Translate>
				</p>
				<form action="https://formspree.io/franco.berton@wavelop.com" method="POST" validate="true">
					<div className={styles.formTop}>
						<input
							className={styles.name}
							name="name"
							type="text"
							placeholder="Name"
							required
							onChange={this.handleInputChange}
						/>
						<input
							className={styles.email}
							name="email"
							type="email"
							placeholder="Email"
							required
							onChange={this.handleInputChange}
						/>
						<input
							className={styles.phone}
							name="phome"
							type="text"
							placeholder="Phone"
							onChange={this.handleInputChange}
						/>
					</div>
					<div className={styles.formCenter}>
						<textarea
							className={styles.message}
							name="message"
							placeholder="Messaggio"
							required
							onChange={this.handleInputChange}
						/>
					</div>
					<div className={styles.formBottom}>
						<div className={styles.formCheckBoxContainer}>
							<label className={styles.formCheckBoxItem} htmlFor="web">
								<input
									type="checkbox"
									className={styles.checkbox}
									id="web"
									name="web"
									checked={!!this.state.form.web}
									onChange={this.handleInputChange}
								/>
								<span className={styles.checkmark} />
								Web application
							</label>
							<label className={styles.formCheckBoxItem} htmlFor="sap">
								<input
									type="checkbox"
									className={styles.checkbox}
									id="sap"
									name="sap"
									checked={!!this.state.form.sap}
									onChange={this.handleInputChange}
								/>
								<span className={styles.checkmark} />
								SAP consulting
							</label>
							<label className={styles.formCheckBoxItem} htmlFor="as400">
								<input
									type="checkbox"
									className={styles.checkbox}
									id="as400"
									name="as400"
									checked={!!this.state.form.as400}
									onChange={this.handleInputChange}
								/>
								<span className={styles.checkmark} />
								AS400 consulting
							</label>
						</div>
						<button className={styles.sendButton}>Send</button>
					</div>
				</form>
			</Strip>
		);

		let generalContactsStripTag = (
			<Strip className={styles.generalContactsStrip}>
				<div className={styles.generalContactsContainer}>
					<div className={styles.generalContactsLeftSide}>
						<h1 className={styles.generalContactsTitle}>
							<Translate>contactPage.generalContact</Translate>
						</h1>
						<div className={styles.generalContactsInfo}>
							<p>GAM Group s.r.l.</p>
							<p>
								P.IVA {fields.vat} {fields.address.address}
							</p>
							<p>
								{fields.address.cap} {fields.address.city} {fields.address.country}
							</p>
							<p>TEL {fields.telephone}</p>
							<p>FAX {fields.fax}</p>
						</div>
					</div>
					<div className={styles.generalContactsRightSide}>
						<Map center={[45.661188, 12.277043]} zoom={17} height={370}>
							<Marker anchor={[45.661188, 12.277043]} payload={1} />
							<Overlay anchor={[45.661188, 12.277043]} offset={[120, 79]}>
								<img src="pigeon.jpg" width={240} height={158} alt="" />
							</Overlay>
						</Map>
					</div>
				</div>
			</Strip>
		);

		let socialButtonsStripTag = (
			<Strip className={styles.socialButtonsStripTag}>
				<div className={styles.socialButtonsContainer}>
					<a href={fields.social.linkedin} className={styles.socialButtons}>
						Linkedin
					</a>
					<a href={fields.social.facebook} className={styles.socialButtons}>
						Facebook
					</a>
				</div>
			</Strip>
		);

		return (
			<Layout metadata={this.props.data.page.yoast}>
				{headerStripTag}
				{formStripTag}
				{generalContactsStripTag}
				{socialButtonsStripTag}
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Contacts);

// Look here to more example: https://www.gatsbyjs.org/docs/graphql-reference/
export const pageQuery = graphql`
	query($id: String!) {
		page: wordpressPage(id: { eq: $id }) {
			title
			slug
			content
			date(formatString: "MMMM DD, YYYY")
			language: polylang_current_lang_code
			polylang_current_lang
			polylang_translations {
				id
				slug
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
			acf {
				subtitle
				info_email
				telephone
				fax
				vat
				address {
					address
					cap
					city
					country
				}
				social {
					linkedin
					facebook
					twitter
				}
			}
		}
	}
`;
